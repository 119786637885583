import React from "react"

const faqs = [
  {
    id: 1,
    question: "What airport transfers are available?",
    answer:
      "We offer airport taxi service and airport transfer to and from New Haven Airport, Logan International Airport, Providence, John F. Kennedy, and LaGuardia airport.",
  },
  {
    id: 2,
    question: "What cities do you service?",
    answer:
      "Bloomfield, Cromwell, East Hartford, East Windsor, Farmington, Glastonbury, Granby, Hartford, Manchester, Middletown, Newington, Portland, Rocky Hill, Simsbury, South Windsor, Vernon, West Hartford, Wethersfield, and Windsor.",
  },
  {
    id: 3,
    question: "Can I travel with a child or baby?",
    answer:
      "We know that every family is different, but at the time of booking you can advise us if your little one needs a car seat. You can either bring your own or we can provide the car seat at no extra cost.",
  },
  {
    id: 4,
    question:
      "I've left something in one of your taxi cabs, how can I get it back?",
    answer:
      "We know it happens and we know it's a pain, but if you've forgotten your belongings in one of our taxi's please contact us and we'll make arrangements to return your belongings.",
  },
  {
    id: 5,
    question: "How can I pay for airport transfer or taxi cab?",
    answer:
      "You can pay with cash or we also accept all major credit cards including Visa, Mastercard, American Express, and Discover.",
  },
  {
    id: 6,
    question: "Do have to share a cab?",
    answer:
      "No, you do not have to share a cab with anyone else. All of our transfers are private and for exclusive use to you or your group, only.",
  },
]

const HomeFAQ = () => {
  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center">
        Frequently asked questions
      </h2>
      <div className="mt-12">
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
          {faqs.map(faq => (
            <div key={faq.id}>
              <dt className="text-lg leading-6 font-bold text-gray-900">
                {faq.question}
              </dt>
              <dd className="mt-2 text-base text-gray-600">{faq.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:mt-14">
        {/* <Link
          to={`/faq`}
          className="flex items-center justify-center px-8 py-3 border border-transparent bg-gray-900 text-base font-medium rounded-md text-white bg-black hover:bg-amber-700 md:py-4 md:px-10 text-2xl"
        >
          View More FAQ
        </Link> */}
      </div>
    </div>
  )
}

export default HomeFAQ
