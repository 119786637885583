import * as React from "react"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import HomeFAQ from "../components/home/HomeFAQ"
import HomeIntro from "../components/home/HomeIntro"
import HomeFeatures from "../components/home/HomeFeatures"
import ScheduleAhead from "../components/home/ScheduleAhead"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import WhyChooseUs from "../components/home/WhyChooseUs"

import { getImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {
  const heroImage = getImage(data.fileName)
  return (
    <Layout>
      <Seo title="Home" />
      <div className="bg-black">
        <div className="container mx-auto">
          <Hero
            heading="Hartford Taxi Service"
            subheading="24-Hour Transportation"
            description="Affordable transportation in Hartford, CT. Here for business?
                  Need a reliable airport transfer? Night out on the town? We have
                  you covered!"
            heroImage={heroImage}
            imageAlt="Image of a taxi cab at night"
          />
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto">
          <HomeIntro />
        </div>
      </div>
      <div className="bg-white mb-12">
        <div className="container mx-auto">
          <HomeFeatures />
        </div>
      </div>
      <div className="bg-white mb-12">
        <div className="container mx-auto">
          <ScheduleAhead />
        </div>
      </div>
      <div className="bg-gray-50">
        <div className="container mx-auto">
          <HomeFAQ />
        </div>
      </div>
      <div className="bg-black">
        <div className="container mx-auto">
          <WhyChooseUs />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    allSite {
      edges {
        node {
          siteMetadata {
            defaultTitle
          }
        }
      }
    }
    fileName: file(relativePath: { eq: "hero-taxi-night.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 980)
      }
    }
  }
`

export default IndexPage
