import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Icon from "../Icon"
import config from "../../../siteConfig"

const ScheduleAhead = () => {
  const { phoneNumber } = config.siteMetadata
  return (
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <StaticImage
              className="h-full w-full object-cover"
              src="../../images/girl-on-phone.jpg"
              alt="A girl scheduling a cab on her phone"
            />
            <div className="absolute inset-0 bg-gray-700 mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">Schedule your ride</span>
              <span className="block text-yellow-300">ahead of time</span>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-gray-300 sm:max-w-3xl">
              You don't have to use an Uber taxi or Lyft to schedule
              transporation ahead of time. Want to schedule your airport taxi in
              advance? Call us and schedule your cab, now!
            </p>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="mx-auto">
                <a
                  href={`tel:${phoneNumber}`}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent bg-yellow-400 text-base font-medium rounded-md text-white bg-black hover:bg-amber-700 md:py-4 md:px-10 text-2xl"
                >
                  <div className="w-8 mr-3">
                    <Icon name="phone-icon" />
                  </div>
                  {phoneNumber}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleAhead
