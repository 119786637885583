import React from "react"
import Icon from "../Icon"

const features = [
  {
    name: "Airport Transfers",
    description:
      "We provide convenience and luxury to our clients who wish to transfer to and from the airport. We have a wide-ranging fleet of cars, minivans and SUVs that drive safely with drivers knowledgeable about all destinations, both near and far. Not going to Bradley International Airport? We also offer long-distance accommodations to and from New Haven Airport, Logan International Aiirport in Boston, Newark Airport in New Jersey, and John F. Kennedy and LaGuardia airport in New York!",
    icon: "airplane-icon",
  },
  {
    name: "College and Prep School Transportation",
    description:
      "School is tough enough without having to worry about getting there. That’s why we offer reliable transportation services for all students needing to travel to UConn, University of Hartford, Goodwin, Trinity, Saint Joseph, Wesleyan University, Hartford Seminary, Charter Oak State College, Middlesex Community College, and other area schools including all Preparatory schools in Connecticut. No matter if you are traveling for a break or to begin a semester at your new school, we can take care of your transportation to school or the airport safely.",
    icon: "school-icon",
  },
  {
    name: "Wheelchair Accessible",
    description:
      "We're here to make your trip as smooth and predictable as possible. When you need a wheelchair-friendly taxi, call us! Our vans allow for easy entry thanks in part by an accessible back folding gate which securely holds onto the chair while giving passengers plenty of room up front where they can sit comfortably during their ride with us.",
    icon: "wheelchair-icon",
  },
  {
    name: "Share a ride with family and friends",
    description:
      "If you want to fit more in your car than just yourself and a few pieces of luggage, we have the right vehicle for every occasion! Our mini-vans can comfortably seat up to 7 passengers while our SUVs offer seating for 8 people. When traveling with friends or family members who are all unique individuals please consider renting one that will accommodate their needs as well so everyone feels comfortable on any journey ahead - no matter how big it gets!",
    icon: "group-icon",
  },
]

export default function Example() {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Hartford Taxi Service: Your car, your driver, every time
          </p>
          <p className="mt-4 max-w-4xl text-xl text-gray-500 lg:mx-auto">
            We are the right taxi provider for travelers who are searching for
            the best and renowned cab service in the Greater Hartford area. We
            aim at delivering convenient and affordable car services across the
            Greater Hartford, Windsor, Glastonbury, Middletown areas.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map(feature => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                    <Icon
                      name={feature.icon}
                      className="h-6 w-6 text-color-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-bold text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
