import React from "react"

/* This example requires Tailwind CSS v2.0+ */
const list = [
  {
    title: "Hassle free taxi service",
    description:
      "Hartford Taxi Service has been providing reliable taxi service for over 20 years, and we're proud of it. We don't want our customers to worry about their ride anymore. That's why we offer 24/7 support and quick response times in an effort to make your trip as smooth as possible.",
  },
  {
    title: "Highest level of customer service",
    description:
      "Our professional drivers will take care of all your transport needs with the highest level of customer service possible. Whether you need help getting around town or picking up or dropping off at the airport, call Hartford Taxi Service today!",
  },
  {
    title: "Our fleet can be tracked in real-time!",
    description:
      "We offer the best taxi service in Hartford that is reliable, affordable, and safe for everyone. We arrive on time every time because we have GPS tracking for each vehicle so we can monitor their location in real-time from our dispatch center.",
  },
  {
    title: "High quality service that is affordable",
    description:
      "Our goal is to provide high-quality transportation at all times, whether it’s for business or pleasure. We strive to make every ride memorable by providing quality customer service from start to finish. Whether you need a ride downtown or across state lines, we have an affordable option for everyone!",
  },
  {
    title: "Reliability is our mantra",
    description:
      "Getting stuck at the airport is one of the worst things that could happen to anyone. You don’t want to miss your flight because of unreliable transportation service. This is why we provide timely airport transfer with our fleet of cars, minivans, and SUVs driven by knowledgeable drivers who know all destinations both near and far.",
  },
  {
    title: "Our fleet is accessible to everyone!",
    description:
      "Hartford Taxi Service is here to help. We are the only wheelchair-accessible taxi service in the area, and we have a fleet of accessible vans that will transport you comfortably from your home or office to any destination within our service area.",
  },
]

export default function WhyChooseUs() {
  return (
    <div className="bg-black">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2
          id="why-choose-us"
          className="text-3xl font-extrabold text-white text-center"
        >
          Why you should choose Hartford Taxi Service
        </h2>
        <div className="mt-12">
          <ul className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
            {list.map((item, i) => (
              <li key={i} className="mt-2 ">
                <h3 className="text-lg font-bold text-yellow-400">
                  {item.title}
                </h3>
                <p className="text-base text-gray-300">{item.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
