import React from "react"

/* This example requires Tailwind CSS v2.0+ */
export default function HomeIntro() {
  return (
    <div className="bg-gray-50 pt-12 sm:pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              24-Hour Affordable, Reliable, and Safe Transportation
          </h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
          We are the most reliable and affordable taxi service in all of Connecticut. We provide fast rides across town for any time of day or night, no matter where you're headed! Our 24-hour airport pick up/drop off facilities with transportation around Bradley International Airport means that we'll be there when it matters most--whether its to get home safe after a late flight; chauffeur your party on special occasions such as weddings & graduations - even just running errands around town!
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Hours</dt>
                  <dd className="order-1 text-5xl font-extrabold text-yellow-400">24</dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Days a Week</dt>
                  <dd className="order-1 text-5xl font-extrabold text-yellow-400">7</dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Days a Year</dt>
                  <dd className="order-1 text-5xl font-extrabold text-yellow-400">365</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
